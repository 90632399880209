.typeanimation::after {
  content: '_';
  color: #D82427;
  font-weight: bolder;
  animation: cursor 1.1s infinite step-start;
}

@keyframes cursor {
  50% {
    opacity: 0;
  }
}